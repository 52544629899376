/**
 * @author johan.ahman@svenskaspel.se (Johan Åhman)
 */
'use strict';

import PropTypes from 'prop-types';
import '../../stylesheets/ribbon.less';
import '../../stylesheets/ribbon-banner.less';

/**
 * Ribbon used to promote new products or add extra info for product.
 */
const RibbonBanner = (props) => {
  const { branding, className, direction, children, size, offsetTop } = props;
  const classNames = ['ribbon ribbon-banner'];
  let styles = {};

  if (branding) {
    classNames.push(`ribbon-banner-branding-${branding}`);
  } else {
    classNames.push('ribbon-banner-branding-default');
  }

  if (direction) {
    classNames.push(`ribbon-banner-direction-${direction}`);
  } else {
    classNames.push('ribbon-banner-direction-default');
  }

  if (className) {
    classNames.push(className);
  }

  if (size) {
    classNames.push(`ribbon-banner-size-${size}`);
  } else {
    classNames.push(`ribbon-banner-size-default`);
  }

  if (offsetTop) {
    styles = {
      '--offset-top': `${offsetTop}px`
    };
  }
  // Non existing stick prop
  /* eslint-disable react/prop-types, react/destructuring-assignment */
  return (
    <div className={classNames.join(' ')} style={styles}>
      <span className="ribbon-banner-content ribbon-banner-animation">
        {props.stick ?
          (
            <span className="ribbon-banner-content-sticky">
              {props.stick}
            </span>
          ) :
          null}
        <span className="ribbon-banner-content-initial">
          {children}
        </span>
      </span>
    </div>
  );
};
/* eslint-enable react/prop-types, react/destructuring-assignment */

RibbonBanner.propTypes = {
  /**
   * Set branding
   */
  branding: PropTypes.string,
  /**
    * Content
    */
  children: PropTypes.node,
  /**
    * Add custom classes
    */
  className: PropTypes.string,
  /**
   * Set position to left or right
   */
  direction: PropTypes.oneOfType([
    PropTypes.oneOf(['left', 'right']),
    PropTypes.string
  ]),
  /**
   * Distance in pixels from top
   */
  offsetTop: PropTypes.number,
  /**
   * Set size of the ribbon
   */
  size: PropTypes.oneOfType([
    PropTypes.oneOf([100, 200, 300, 400]),
    PropTypes.string
  ])
};

export default RibbonBanner;
